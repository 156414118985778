$(document).on('turbolinks:load', function() {
  if(window.location.href.includes('user/astrologer_questionnaire') || window.location.href.includes('user/questionnaire')){

    let startParent = document.getElementById('astrolog-questions-parent');
    let startAdult = document.getElementById('astrolog-questions-adult');
    let formContainer = document.getElementById('astrologer-form');
    let startButtons = document.getElementById('startButtons');

    let parentForm = document.getElementById('form-parent');
    let adultForm = document.getElementById('form-adult');

    let first_tab = document.getElementById("tab-1");
    let second_tab = document.getElementById("tab-2");

    let parent_current_step;
    let adult_current_step;

    let parent_progress_count;
    let adult_progress_count;

    if(startParent != null){
      startParent.onclick = () => {
        console.log('start parent');
        formContainer.classList.remove('d-none');
        startButtons.classList.add('d-none');
        let parentButton = document.getElementById('tab-1');
        parentButton.classList.add('active');
        parentForm.classList.add('active');
        parentForm.classList.add('show');

      };
    }

    if(startAdult != null){
      startAdult.onclick = () =>{
        console.log('start adult')
        formContainer.classList.remove('d-none');
        startButtons.classList.add('d-none');
        let adultButton = document.getElementById('tab-2');
        adultButton.classList.add('active');
        adultForm.classList.add('active');
        adultForm.classList.add('show');
      };
    }




    function multistepForm(form_id){
      console.log("start working");
      let form = document.getElementById(form_id);
      let step = form.getElementsByClassName('step');
      let submitBtn = document.getElementById(form_id+'_submit-btn');
      let prevBtn = document.getElementById(form_id+'_prev-btn');
      let nextBtn = document.getElementById(form_id+'_next-btn');
      //let succcessDiv = document.getElementById(form_id+'_success');


      console.log(form_id + " : " + step.length)



      let current_step = 0;
      let stepCount = step.length - 1
      step[current_step].classList.add('d-block');
      if (current_step == 0) {
          prevBtn.classList.add('d-none');
          submitBtn.classList.add('d-none');
          nextBtn.classList.add('d-inline-block');
      }

      const progress = (value) => {
        document.getElementsByClassName('progress-bar')[0].style.width = `${value}%`;
      }



      nextBtn.addEventListener('click', () => {
        current_step++;
        parent_current_step = current_step;
        console.log('Step: ' + parent_current_step);
        let previous_step = current_step - 1;
        if ((current_step > 0) && (current_step <= stepCount)) {
            prevBtn.classList.remove('d-none');
            prevBtn.classList.add('d-inline-block');
            step[current_step].classList.remove('d-none');
            step[current_step].classList.add('d-block');
            step[previous_step].classList.remove('d-block');
            step[previous_step].classList.add('d-none');
            if (current_step == stepCount) {
                submitBtn.classList.remove('d-none');
                submitBtn.classList.add('d-inline-block');
                nextBtn.classList.remove('d-inline-block');
                nextBtn.classList.add('d-none');
            }
        } else {
            if (current_step > stepCount) {
                form.onsubmit = () => {
                    return true
                }
            }
        }
        value = (100 / stepCount) * current_step;
        parent_progress_count = value;
        console.log("Progress: "+parent_progress_count+"%");
        progress(value);
      });


      prevBtn.addEventListener('click', () => {
        if (current_step > 0) {
            current_step--;
            parent_current_step = current_step;
            console.log('Step: ' + parent_current_step);
            let previous_step = current_step + 1;
            prevBtn.classList.add('d-none');
            prevBtn.classList.add('d-inline-block');
            step[current_step].classList.remove('d-none');
            step[current_step].classList.add('d-block')
            step[previous_step].classList.remove('d-block');
            step[previous_step].classList.add('d-none');
            if (current_step < stepCount) {
                submitBtn.classList.remove('d-inline-block');
                submitBtn.classList.add('d-none');
                nextBtn.classList.remove('d-none');
                nextBtn.classList.add('d-inline-block');
                prevBtn.classList.remove('d-none');
                prevBtn.classList.add('d-inline-block');
            }
        }

        if (current_step == 0) {
            prevBtn.classList.remove('d-inline-block');
            prevBtn.classList.add('d-none');
        }
        value = (100 / stepCount) * current_step;
        parent_progress_count = value;
        console.log("Progress: "+parent_progress_count+"%");
        progress(value);
      });

      }

      if(startParent != null){
        multistepForm('form-parent');
        $('#parent_phone,#parent_whatsapp_phone').inputmask("+7(999)999-99-99");
      }
      if(startAdult != null){
        multistepForm('form-adult');
        $('#adult_phone,#adult_whatsapp_phone').inputmask("+7(999)999-99-99");
      }

  }
});
