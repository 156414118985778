$(document).on('turbolinks:load', ()=> {

  $('[data-toggle=user-sidebar-collapse]').click(()=>{
    UserSidebarCollapseToggle();
  });


  function UserSidebarCollapseToggle(){
    //console.log('click!');
    $('#user-sidebar-content').toggleClass('d-none');

    
  }

});
