$(document).on('turbolinks:load', function() {
  $('.select, #user_id').on('change', function() {
    const user_id = $(this).val();
    const form_type = $(this).parent().parent().parent().parent().find('#form_type').val();

    console.log(`selected user_id: ${user_id}, form_type: ${form_type}`);
    if ( !!form_type && !!user_id ) {
      $.ajax({
        url: `/api/v1/questionnaires/select?questionnaire_type=${form_type}&user_id=${user_id}`,
        method: "GET",
        dataType: "json",
        async: false,
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          const result = response["result"];
          console.log('result: ', result);
          if ( result['status'] == 'complete' ) {
            alert('Данная анкета уже была заполнена, изменения доступны в административном интерфейсе');
          } else if ( result['status'] == 'draft' ) {
            console.log('update')
            update_data(form_type, result['questionnaire']);
          } else {
            console.log('clean')
            clear_data(form_type);  // Очищаем данные
          }
        }
      });
    }
  });


  function form_eacher(form_id, questionnaire, form_type){
    for (node of form_id.childNodes){
      if(node != null || node != undefined){
        if((node == '[object HTMLInputElement]' || node == '[object HTMLTextAreaElement]') && node.getAttribute('type') != 'checkbox'){
          //console.log(`Inputs: ${node.tagName}:${node.getAttribute('id')}: ` + node.value);
          let field_id = node.getAttribute('id');
          if(field_id != null){
            //console.log('field_id: ' + field_id);
            //console.log('field_id without prefix: ' + field_id.replace(form_type,''));
            let field = document.getElementById(`${field_id}`);
            if (field.getAttribute('id').includes('parent') || field.getAttribute('id').includes('adult')){
              let rep = (form_type == 'parent' || form_type == 'astrologer-parent') ? /parent_/gi : /adult_/gi;
              field.value = questionnaire[`${field_id.replace(rep,'')}`];
            }
          }
        }
        if(node == '[object HTMLInputElement]' && node.getAttribute('type') == 'checkbox'){
          //console.log(`Checkboxes: ${node.tagName}:${node.getAttribute('id')}: ` + node.value);
          let field_id = node.getAttribute('id');
          let field_value = '_' + node.getAttribute('value');
          if(field_id != null){
            let rep = (form_type == 'parent' || form_type == 'astrologer-parent') ? /parent_/gi : /adult_/gi;
            let cut_id = field_id.replace(field_value,'').replace(rep,'');
            let cut_id_prefix = field_id.replace(field_value,'');
            //console.log(`cut id: ${cut_id}, cut id with prefix: ${cut_id_prefix}`)
            if(questionnaire[`${cut_id}`] != null){
              /*for(const i of questionnaire[`${cut_id}`]){
                console.log('i is ' + i);
                const elem = document.getElementById(`${cut_id_prefix}_${i}`);
                if(elem != null){
                  elem.checked = true;
                }
              }*/
              if(questionnaire[`${cut_id}`].includes(`${node.getAttribute('value')}`)){
                let elem = document.getElementById(`${field_id}`);
                elem.checked = true;
              }else{
                let elem = document.getElementById(`${field_id}`);
                elem.checked = false;
              }
            }
          }
        }
        if(node == '[object HTMLInputElement]' && node.getAttribute('type') == 'date'){
          //console.log('datetime: ' + node.tagName + ' ' + node.getAttribute('id'));
          let field_id = node.getAttribute('id');
          let elem = document.getElementById(field_id);
          //console.log(`field id is: ${field_id}`)
          if(elem != null){
            let rep = (form_type == 'parent' || form_type == 'astrologer-parent') ? /parent_/gi : /adult_/gi;
            let date = new Date(questionnaire[`${field_id.replace(rep,'')}`])
            //console.log(date)
            //let current_date = test.toISOString().substring(0,10);
            elem.value = date.toISOString().substring(0,10);
          }
        }
        if(node.hasChildNodes){
          form_eacher(node, questionnaire, form_type);
        }
      }
    }
  }

  function form_cleaner(form_id, form_type){
    console.log('lets clean!')
    for (node of form_id.childNodes){
      if(node != null || node != undefined){
        if(node == '[object HTMLInputElement]' || node == '[object HTMLTextAreaElement]'){
          if(node.getAttribute('type') != 'checkbox'){
            let field_id = node.getAttribute('id');
            if(field_id != null){
              let field = document.getElementById(field_id);
              if (field.getAttribute('id').includes('parent') || field.getAttribute('id').includes('adult')){
                console.log(`field cleaner: ${field_id}`);
                field.value = ''
              }
            }

          }
          if(node.getAttribute('type') == 'checkbox'){
            let field_id = node.getAttribute('id');
            if(field_id != null){
              let field = document.getElementById(field_id);
              console.log(`checkbox cleaner: ${field_id}`);
              field.checked = false;
            }

          }
          if(node.getAttribute('type') == 'date'){
            let field_id = node.getAttribute('id');
            if(field_id != null){
              let field = document.getElementById(field_id);
              console.log(`date cleaner: ${field_id}`);
              field.value = ''
            }

          }
        }
        if(node.hasChildNodes){
          form_cleaner(node, form_type);
        }
      }
    }
  }


  /*function form_eacher_2(form_type, questionnaire){
    const postfix = (form_type == 'astrologer-parent') ? 'parent' : (form_type == 'astrologer-adult') ? 'adult' : form_type;
    const form_id =  `form-${postfix}`;
    console.log('postfix: ' + form_id );


    let entry_form = $(`#${form_id}`);
    console.log('postfix: ' + entry_form.contents('#steps-container'));


  }*/


  function update_data(form_type, questionnaire) {
    console.log('form_type: ', form_type);

    //for recursion
    const postfix = (form_type == 'astrologer-parent') ? 'parent' : (form_type == 'astrologer-adult') ? 'adult' : form_type;
    let current_form = document.getElementById(`form-${postfix}`);

    //let current_form =  `form-${postfix}`;
    console.log('postfix: ' +  postfix)

    if ( form_type == 'parent' ) {



      //form_eacher_2(form_type, questionnaire);
      form_eacher(current_form, questionnaire,form_type);


      //$('#parent_fullname_child').val(questionnaire.fullname_child);
      //$('#parent_email').val(questionnaire.email);
      //$('#parent_phone').val(questionnaire.phone);



      //$('#parent_education_other').val(questionnaire.education_other);
      //$('#parent_workplace_position').val(questionnaire.workplace_position);
      //$('#parent_is_future_child_profession').val(questionnaire.is_future_child_profession);
      //$('#parent_is_share_your_view').val(questionnaire.is_share_your_view);
      //$('#parent_child_next_one_year').val(questionnaire.child_next_one_year);
      //$('#parent_child_next_five_year').val(questionnaire.child_next_five_year);
      //$('#parent_child_next_twenty_year').val(questionnaire.child_next_twenty_year);
      //$('#parent_what_child_prof_favorite').val(questionnaire.what_child_prof_favorite);
      //$('#parent_child_favorite_activities').val(questionnaire.child_favorite_activities);
      //$('#parent_child_dignity').val(questionnaire.child_dignity);
      //$('#parent_child_flaw').val(questionnaire.child_flaw);
      //$('#parent_what_influence_child_profession_other').val(questionnaire.what_influence_child_profession_other);



      //$('#parent_how_help_child_profession_other').val(questionnaire.how_help_child_profession_other);



      //$('#parent_how_help_child_profession_other_v2').val(questionnaire.how_help_child_profession_other_v2);



      //$('#parent_whatsapp_phone').val(questionnaire.whatsapp_phone);

    } else if ( form_type == 'adult' ) {
      //$('#adult_resume').val(questionnaire.resume);
      //$('#adult_email').val(questionnaire.email);
      //$('#adult_phone').val(questionnaire.phone);
      //$('#adult_current_specialty').val(questionnaire.current_specialty);

      //form_eacher_2(form_type, questionnaire);
      form_eacher(current_form, questionnaire,form_type);

      //$('#adult_current_position').val(questionnaire.current_position);
      //$('#adult_experience').val(questionnaire.experience);
      //$('#adult_workplace_interesting').val(questionnaire.workplace_interesting);
      //$('#adult_what_change_in_work').val(questionnaire.what_change_in_work);
      //$('#adult_what_my_ploblem').val(questionnaire.what_my_ploblem);
      //$('#adult_how_implement_in_work').val(questionnaire.how_implement_in_work);
      //$('#adult_what_important_in_work').val(questionnaire.what_important_in_work);
      //$('#adult_what_achieve_result').val(questionnaire.what_achieve_result);
      //$('#adult_what_stopping_now').val(questionnaire.what_stopping_now);
      //$('#adult_what_already_tried').val(questionnaire.what_already_tried);
      //$('#adult_when_achieve_result').val(questionnaire.when_achieve_result);
      //$('#adult_whatsapp_phone').val(questionnaire.whatsapp_phone);



    } else if ( form_type == 'astrologer-parent' ) {
      //form_eacher_2(form_type, questionnaire);
      form_eacher(current_form, questionnaire,form_type);
/*
      $('#parent_fullname_child').val(questionnaire.fullname_child);
      $('#parent_birthday').val(questionnaire.birthday);
      $('#parent_birthday_child').val(questionnaire.birthday_child);



      $('#parent_description_of_family_situation').val(questionnaire.description_of_family_situation);
      $('#parent_teenager_body_description').val(questionnaire.teenager_body_description);
      $('#parent_brothers_and_sisters_birthday').val(questionnaire.brothers_and_sisters_birthday);
      $('#parent_parents_information').val(questionnaire.parents_information);
*/

    } else if ( form_type == 'astrologer-adult' ) {
      //form_eacher_2(form_type, questionnaire);
      form_eacher(current_form, questionnaire,form_type);
      /*$('#adult_birth_place').val(questionnaire.birth_place);
      $('#adult_birthday').val(questionnaire.birthday);



      $('#adult_parents_information').val(questionnaire.parents_information);
      $('#adult_description_of_family').val(questionnaire.description_of_family);
      $('#adult_description_of_childhood').val(questionnaire.description_of_childhood);
      $('#adult_description_about_grandpas_and_grandmas').val(questionnaire.description_about_grandpas_and_grandmas);
      $('#adult_education').val(questionnaire.education);
      $('#adult_professions_and_works').val(questionnaire.professions_and_works);
      $('#adult_marriage_information').val(questionnaire.marriage_information);
      $('#adult_significant_events').val(questionnaire.significant_events);
      $('#adult_about_hobby').val(questionnaire.about_hobby);
*/
    }
  }

  function clear_data(form_type) {

    const postfix = (form_type == 'astrologer-parent') ? 'parent' : (form_type == 'astrologer-adult') ? 'adult' : form_type;
    let current_form = document.getElementById(`form-${postfix}`);

    console.log('form_type: ', form_type);
    if ( form_type == 'parent' ) {
      form_cleaner(current_form, form_type);
    /*  $('#parent_fullname_child').val('');
      $('#parent_email').val('');
      $('#parent_phone').val('');

      for ( const education of ['higher', 'edu_average', 'sec_special', 'inc_higher', 'sec_higher', 'academ_degree', 'edu_other'] ) {
        const item_id = `#education_${education}`;
        $(item_id).prop('checked', false);
      }
      $('#parent_education_other').val('');

      $('#parent_workplace_position').val('');
      $('#parent_is_future_child_profession').val('');
      $('#parent_is_share_your_view').val('');
      $('#parent_child_next_one_year').val('');
      $('#parent_child_next_five_year').val('');
      $('#parent_child_next_twenty_year').val('');
      $('#parent_what_child_prof_favorite').val('');
      $('#parent_child_favorite_activities').val('');
      $('#parent_child_dignity').val('');
      $('#parent_child_flaw').val('');
      $('#parent_what_influence_child_profession_other').val('');

      for ( const what_influence_child_profession of ['studio','advice','recommendation','myself','training','friend','inf_other','section','prof_circle','mass_media'] ) {
        const item_id = `#what_influence_child_profession_${what_influence_child_profession}`;
        $(item_id).prop('checked', false);
      }

      $('#parent_how_help_child_profession_other').val('');

      for ( const how_help_child_profession_v1 of ['help_study','talking_profession','study_circle','study_profession','dont_help','dont_know','hlpv1_other'] ) {
        const item_id = `#how_help_child_profession_v1_${how_help_child_profession_v1}`;
        $(item_id).prop('checked', false);
      }

      $('#parent_how_help_child_profession_other_v2').val('');

      for ( const how_help_child_profession_v2 of ['not_demand','not_attention','control','demand_assist'] ) {
        const item_id = `#how_help_child_profession_v2_${how_help_child_profession_v2}`;
        $(item_id).prop('checked', false);
      }

      for ( const you_satisfied_child_school of ['general_satisfy','dont_care','study_better','complete_satisfy'] ) {
        const item_id = `#you_satisfied_child_school_${you_satisfied_child_school}`;
        $(item_id).prop('checked', false);
      }

      $('#parent_whatsapp_phone').val('');*/

    } else if ( form_type == 'adult' ) {
      form_cleaner(current_form, form_type);
    /*  $('#adult_resume_link').val('');
      $('#adult_email').val('');
      $('#adult_phone').val('');

      $('#adult_current_specialty').val('');

      for ( const roles_work of ['mentor','supervisor','motivator','role_other','generator','organizer','performer','expert','sole','diplomat','finisher','critic','assistant'] ) {
        const item_id = `#roles_work_${roles_work}`;
        $(item_id).prop('checked', false);
      }

      $('#adult_current_position').val('');
      $('#adult_experience').val('');
      $('#adult_workplace_interesting').val('');
      $('#adult_what_change_in_work').val('');
      $('#adult_what_my_ploblem').val('');
      $('#adult_how_implement_in_work').val('');
      $('#adult_what_important_in_work').val('');
      $('#adult_what_achieve_result').val('');
      $('#adult_what_stopping_now').val('');
      $('#adult_what_already_tried').val('');
      $('#adult_when_achieve_result').val('');
      $('#adult_whatsapp_phone').val('');*/

    } else if ( form_type == 'astrologer-parent' ) {
      form_cleaner(current_form, form_type);
/*
      $('#parent_fullname_child').val('');
      $('#parent_birthday').val('');
      $('#parent_birthday_child').val('');

      for ( const birth_time of ['dont_know','know_exact','bt_other'] ) {
        const item_id = `#birth_time_${birth_time}`;
        $(item_id).prop('checked', false);
      }

      for ( const information_source of ['did_rectification_from_astrologer','from_words_of_another_relatives','tag_from_maternity_hospital','is_other','form_mother_words'] ) {
        const item_id = `#information_source_${information_source}`;
        $(item_id).prop('checked', false);
      }

      $('#parent_description_of_family_situation').val('');
      $('#parent_teenager_body_description').val('');
      $('#parent_brothers_and_sisters_birthday').val('');
      $('#parent_parents_information').val('');
*/
    } else if ( form_type == 'astrologer-adult' ) {
      form_cleaner(current_form, form_type);
/*
      $('#adult_birth_place').val('');
      $('#adult_birthday').val('');

      for ( const birth_time of ['dont_know','know_exact','bt_other'] ) {
        const item_id = `#birth_time_${birth_time}`;
        $(item_id).prop('checked', false);
      }

      for ( const information_source of ['did_rectification_from_astrologer','from_words_of_another_relatives','tag_from_maternity_hospital','is_other','form_mother_words'] ) {
        const item_id = `#information_source_${information_source}`;
        $(item_id).prop('checked', false);
      }

      $('#adult_parents_information').val('');
      $('#adult_description_of_family').val('');
      $('#adult_description_of_childhood').val('');
      $('#adult_description_about_grandpas_and_grandmas').val('');
      $('#adult_education').val('');
      $('#adult_professions_and_works').val('');
      $('#adult_marriage_information').val('');
      $('#adult_significant_events').val('');
      $('#adult_about_hobby').val('');
*/
    }
  }

  // if(window.location.href.includes('user/astrologer_questionnaire') || window.location.href.includes('user/questionnaire')){
  //   let adult = document.getElementById('form-adult')
  //   let parent = document.getElementById('form-parent')
  //   if(adult != null){
  //     //questionnaire
  //
  //   }
  //   if(parent != null){
  //     console.log('contains parent form')
  //   }
  // }else{
  //   console.log('link not contains')
  // }
  // function checkboxListener(checkboxName){
  //   // listener for checkbox
  // }

})
