class CardEngine{
  is_chosen = false;
  is_inArray = false;
  is_without_pair = true;
  is_for_replace = false;
  constructor(id, title, description){
    this.id = id;
    this.title = title;
    this.description = description;
  }
  card_info(){
    return `'id': ${this.id}, 'title': ${this.title}, 'description': ${this.description}`;
  }

  set chosen(value){
    this.is_chosen = value;
  }

  get chosen(){
    return this.is_chosen;
  }

}


class IntrapersonalCard extends CardEngine{
  constructor(id,title,description){
    super(id, title, description);

  }
}

class ProfessionCard extends CardEngine{

  constructor(id,title,description,category,color,type){
    super(id, title, description);
    this.category = category;
    this.color = color;
    this.type = type;
  }

  card_info() {
    return `'id': ${this.id}, 'name': ${this.title}, 'description': ${this.description},
            'category': ${this.category}, 'color': ${this.color}, type: ${this.type.title}, descr: ${this.type.description}`;
  }
}

class CardTable{
  cards_array = []
  pairs = []
  current_stage = 1
  current_step = 1
  stage_limit = 3
  without_pair_array = []
  stage_result = []
  stage_result_final = []
  card_ids = []

  constructor(){
    this.card_init()
    let container = document.getElementById('profession-cards');

    let paginator = document.getElementById('stage-paginator');

    let card1 = document.getElementById('card-1');
    let card2 = document.getElementById('card-2');

    let card1_checked = document.getElementById('card1-checked');
    let card2_checked = document.getElementById('card2-checked');

    let card_title1 = document.getElementById('card-title-1');
    let card_title2 = document.getElementById('card-title-2');

    let card_text1 = document.getElementById('card-text-1');
    let card_text2 = document.getElementById('card-text-2');

    let stage_html = document.getElementById('stage-num');
    let current_step_html = document.getElementById('current_step');
    let steps_html = document.getElementById('steps');

    this.set_card_pairs(this.cards_array);
    let steps = this.pairs.length;
    stage_html.innerHTML = this.current_stage;
    current_step_html.innerHTML = this.current_step;
    steps_html.innerHTML = steps;

    let nextBtn = document.getElementById('nextBtn');
    let finishBtn = document.getElementById('finishBtn');
    let hideBtn = document.getElementById('hideBtn2');
    let hideBtn2 = document.getElementById('hideBtn1');
    let backBtn = document.getElementById('backBtn');


    this.card_html_init(card1, card_title1, card_text1, 0);
    this.card_html_init(card2, card_title2, card_text2, 1);

    //this.next_pair();
    nextBtn.addEventListener('click',()=>{
      console.log('---Test output start---');
      //console.log('Cards array: ' + this.cards_array);

      console.log('steps: ' + steps)
      console.log('Current stage: ' + this.current_stage);
      console.log('pairs len: ' + this.pairs.length)
      console.log('---Test output end---');
      if(this.current_stage < this.stage_limit){
        if(this.current_step < steps){
          console.log('steps: ' + steps)

          card1_checked.classList.add('hide');
          card2_checked.classList.add('hide');
          if(this.pairs[this.current_step] != undefined){
            if(this.pairs[this.current_step][1] != undefined || this.pairs[this.current_step][1] != null){
              console.log('in card step: ' + this.current_step)
              this.card_html_update(this.pairs, card1, card_title1, card_text1, 0);
              this.card_update(this.pairs, this.current_step, 0);

              this.card_html_update(this.pairs, card2, card_title2, card_text2, 1);
              this.card_update(this.pairs, this.current_step, 1);

            }else{
              this.stage_result.push(this.pairs[this.current_step-1][0]);
              this.pairs[this.current_step-1][0].is_inArray = false;
              this.pairs[this.current_step-1][0].is_chosen = false;
              //console.log(`chosen iss ${this.pairs[this.current_step-1][0].title} | step: ${this.current_step}`);
            }

            console.log(`chosen cards count ${this.stage_result.length}`)
            hideBtn.classList.remove('hide');
            nextBtn.classList.add('hide');


        }
        console.log('cerrent step testing: ' + this.current_step)
        if(this.current_step >= 0){
          hideBtn2.classList.add('hide');
          backBtn.classList.remove('hide')
        }else{
          hideBtn2.classList.remove('hide');
          backBtn.classList.add('hide')
        }
        this.current_step++;



        console.log('Current step: ' + this.current_step);
        current_step_html.innerHTML = this.current_step;

        }else{
          if(this.pairs[this.current_step-1][1] != undefined || this.pairs[this.current_step-1][1] != null){
            this.card_update(this.pairs, this.current_step, 0);
            this.card_update(this.pairs, this.current_step, 1);
          }else{
            this.stage_result.push(this.pairs[this.current_step-1][0]);
            this.pairs[current_step-1][0].is_inArray = false;
            this.pairs[current_step-1][0].is_chosen = false;
            console.log(`chosen is ${this.pairs[this.current_step-1][0].title} | step: ${this.current_step}`);
          }
          console.log(`result is ${this.stage_result}`);
          this.pairs = []
          this.set_card_pairs(this.stage_result);
          console.log('resultat: ' + this.stage_result)
          this.stage_result_final = this.stage_result
          console.log('resultat final: ' + this.stage_result_final)
          /*for(i of this.without_pair_array){
            console.log(`cards without pair: ${i.card_info()}`)
          }*/

          this.current_stage++;
          this.current_step = 1;
          steps = this.pairs.length;
          current_step_html.innerHTML = this.current_step;
          steps_html.innerHTML = steps;
          stage_html.innerHTML = this.current_stage;

          this.ending(container, paginator, stage_html, nextBtn, finishBtn);

          console.log('this???: ' + card1);
          this.card_html_init(card1, card_title1, card_text1, 0);
          this.card_html_init(card2, card_title2, card_text2, 1);


          this.stage_result = [];
        }
      }
    });

    backBtn.addEventListener('click',()=>{
      if(this.current_step > 0){
        card1_checked.classList.add('hide');
        card2_checked.classList.add('hide');
        this.current_step--
        console.log('current step numba: ' + this.current_step);
        current_step_html.innerHTML = this.current_step;
        if(this.pairs[this.current_step] != undefined){
          if(this.pairs[this.current_step][1] != undefined || this.pairs[this.current_step][1] != null){
            this.card_html_update(this.pairs, card1, card_title1, card_text1, 0, 1);
            this.reset_past_cards(this.pairs, this.current_step, 0);

            this.card_html_update(this.pairs, card2, card_title2, card_text2, 1, 1);
            this.reset_past_cards(this.pairs, this.current_step, 1);

          }else{
            this.stage_result.push(this.pairs[this.current_step-1][0]);
            this.pairs[this.current_step+1][0].is_inArray = false;
            this.pairs[this.current_step+1][0].is_chosen = false;
            //console.log(`chosen iss ${this.pairs[this.current_step-1][0].title} | step: ${this.current_step}`);
          }

          console.log(`chosen cards count ${this.stage_result.length}`)
          hideBtn.classList.remove('hide');
          nextBtn.classList.add('hide');

        }

      }
    });

    this.card_actions(card1,card2,card1_checked,card2_checked,hideBtn);


  }

  card_actions(card1,card2,card1_checked,card2_checked,hideBtn){
    card1.addEventListener('click',()=>{
      console.log(`stage ${this.current_stage}`);
      console.log(`card1 Step ${this.current_step-1}`);
      nextBtn.classList.remove('hide');
      hideBtn.classList.add('hide');


      console.log('this is from pairs1')
      this.pairs[this.current_step-1][0].chosen = true;
      card1_checked.classList.remove('hide');
      console.log(`card1 step ${this.current_step-1} | name: ${this.pairs[this.current_step-1][0].title} | chosen: ${this.pairs[this.current_step-1][0].is_chosen}`);

      if(this.pairs[this.current_step-1][1] != undefined || this.pairs[this.current_step-1][1] != null){
        this.pairs[this.current_step-1][1].chosen = false;
        card2_checked.classList.add('hide');
        console.log(`card2 step ${this.current_step-1} | name: ${this.pairs[this.current_step-1][1].title} | chosen ${this.pairs[this.current_step-1][1].is_chosen}`);
      }

    });

    card2.addEventListener('click',()=>{
      console.log(`stage ${this.current_stage}`);
      console.log(`card2 Step ${this.current_step-1}`);
      nextBtn.classList.remove('hide');
      hideBtn.classList.add('hide');

      if(this.pairs[this.current_step-1][1] != undefined || this.pairs[this.current_step-1][1] != null){
        this.pairs[this.current_step-1][1].chosen = true;
        card2_checked.classList.remove('hide');
          console.log(`card2 step ${this.current_step-1} | name: ${this.pairs[this.current_step-1][1].title} | chosen ${this.pairs[this.current_step-1][1].is_chosen}`);
      }

      this.pairs[this.current_step-1][0].chosen = false;
      card1_checked.classList.add('hide');
      console.log(`card1 step ${this.current_step-1} | name: ${this.pairs[this.current_step-1][0].title} | chosen: ${this.pairs[this.current_step-1][0].is_chosen}`);

    });
  }



  ending(container, stage_html, paginator, nextBtn, finishBtn){


  }

  set_card_pairs(){
    let pair_array = [];
    for(let first_card of this.cards_array){
      for(let second_card of this.cards_array){
        if(second_card.title != first_card.title && second_card.is_inArray != true && first_card.is_inArray != true){
          second_card.is_inArray = true;
          first_card.is_inArray = true;
          second_card.is_without_pair = false;
          first_card.is_without_pair = false;
          this.pairs.push([first_card,second_card]);
        }
      }
    }

    for(let card of this.cards_array){
      if(card.is_inArray == false){
        //pair_array.push([card]);
        this.without_pair_array.push(card);
        console.log(`${card.title} is without pair: ${card.is_without_pair}`)
      }
    }
    for(let current = 0;current<pair_array.length;current++){
      console.log(`in set card pairs 1: ${pair_array[current][0].title} | 2: ${pair_array[current][1] == undefined ? null : pair_array[current][1].title}`);
    }
    return pair_array;
  }

  card_html_init(card, card_title, card_text, card_index){
    console.log("card: " + card)
    console.log("pairs: " + this.pairs)
    card.style.backgroundColor = this.pairs[0][card_index].color;
    card_title.innerHTML = this.pairs[0][card_index].title;
    card_text.innerHTML = this.pairs[0][card_index].description;
  };


  card_init(){
    this.cards_array = CardEngine.new()
  };

  group_render(card_array, res_container){
    let group_array = this.card_array_groups(this.stage_result);
    console.log('rendering...');
    //let res_container = document.getElementById('profession-cards_result');
    res_container.classList.remove('hide');

    let elements = res_container.getElementsByClassName('group-row'); // Обновление результата
    while(elements[0]){
      elements[0].parentNode.removeChild(elements[0]);
    }

    for(let group = 0; group < group_array.length; group++){

      let result_card_row = document.createElement('div');
      result_card_row.classList.add('group-row');
      result_card_row.setAttribute('id',`group-${group}`);

      console.log(result_card_row);
      res_container.append(result_card_row);
      for(let card = 0; card < group_array[group].length; card++){

        console.log(`card group is ${group_array[group][card].title}`)
        let result_card_container = document.createElement('div');
        result_card_container.classList.add('result-card_container');
        let result_card_radio = document.createElement('input');
        result_card_radio.classList.add('result-card_radio');
        result_card_radio.setAttribute('type','radio');
        result_card_radio.setAttribute('name','result-card');

        result_card_row.append(result_card_container);
        result_card_container.append(this.result_card_elem_render(group_array[group][card].title, group_array[group][card].color));
        result_card_container.append(result_card_radio);

      }
    }
  }


  card_html_update(card_array, card, card_title, card_text, card_index, current_step_delta = 0){
    card.style.backgroundColor = this.pairs[this.current_step - current_step_delta][card_index].color;
    card_title.innerHTML = this.pairs[this.current_step - current_step_delta][card_index].title;
    card_text.innerHTML = this.pairs[this.current_step - current_step_delta][card_index].description;
    console.log('card ' + card_index + ' updated' + ' with current step:' + this.current_step)
  }



  card_update(card_array, current_step, card_index, current_step_delta = 1){
    if(this.pairs[this.current_step-current_step_delta][card_index].is_chosen == true){
      this.stage_result.push(this.pairs[this.current_step-current_step_delta][card_index]);
      this.pairs[this.current_step-current_step_delta][card_index].is_inArray = false;
      this.pairs[this.current_step-current_step_delta][card_index].is_chosen = false;
      console.log(`chosen is ${this.pairs[this.current_step-current_step_delta][card_index].title} | step: ${this.current_step}`);
    }
  }

  reset_past_cards(card_array, current_step, card_index){
    if(this.stage_result.includes(this.pairs[this.current_step-1][card_index])){
      this.stage_result.pop(this.pairs[this.current_step-1][card_index]);
    }

  }
}

class ProfessionCardTable extends CardTable{
  replace_card_name;
  constructor(){
    super();

    let res_container = document.getElementById('profession-cards_result');
    let card_replacer_container = document.getElementById('prof-card_replacer');

    let second_cell = document.getElementById('replace2');
    let replaceBtn = document.getElementById('btnReplace');

    let card_modal_back = document.getElementById('prof-card_faded-back');
    let card_modal = document.getElementById('prof-card_modal');
    let card_modal_icon = document.getElementById('prof-card_modal-icon');

    let card_modal_exit = document.getElementById('prof-card_modal-exit');
    let card_modal_info = document.getElementById('prof-card_modal-info');

    let card_modal_type = document.getElementById('prof-card_type-help');

    let card_modal_title = document.getElementById('prof-card_modal-title');
    let card_modal_text = document.getElementById('prof-card_modal-text');
    let card_modal_type_title = document.getElementById('prof-card_type-help_title');
    let card_modal_type_text = document.getElementById('prof-card_type-help_text');


    this.card_init();

    this.set_card_pairs(this.cards_array);

    card_modal_exit.addEventListener('click',()=>{
      card_modal_back.classList.add('hide');
    });

    card_modal_info.addEventListener('mouseover',()=>{
      card_modal_type.classList.remove('hide')
    });
    card_modal_info.addEventListener('mouseout',()=>{
      card_modal_type.classList.add('hide')
    });

    //let replace_card_name;
    res_container.addEventListener('click',(e)=>{
      if(e.target.tagName == 'INPUT'){
        console.log(e.target.parentNode.firstChild.innerText);
         //replace_card_name = e.target.parentNode.firstChild.innerText;
         card_element = e.target.parentNode.firstChild;

         if(second_cell.hasChildNodes){
           let child_elem = second_cell.firstChild;
           while(child_elem){
             second_cell.removeChild(child_elem);
             child_elem = second_cell.firstChild;
           }
           second_cell.appendChild(this.result_card_elem_render(card_element.innerText,card_element.style.borderColor));
         }else{
           second_cell.appendChild(this.result_card_elem_render(card_element.innerText,card_element.style.borderColor));
         }

         console.log('card element name: ' + card_element.innerText);

         this.replace_card_name = card_element.innerText;
        //replace_card_by_name(e.target.parentNode.firstChild.innerText,final_stage_result,without_pair_array);
      }
      if(e.target.className == 'result-card'){
        this.modal_init(e.target.innerHTML,this.stage_result,this.without_pair_array);
      }

    });

    res_container.addEventListener('mouseover',(e)=>{
      if(e.target.className == 'result-card'){
        e.target.style.backgroundColor = e.target.style.borderColor;
      }
    });
    res_container.addEventListener('mouseout',(e)=>{
      if(e.target.className == 'result-card'){
        e.target.style.backgroundColor = '#ffffff';
      }
    });

    replaceBtn.addEventListener('click',()=>{
      console.log('replacebtn is working')
      let second_cell = document.getElementById('replace2');
      let first_cell = document.getElementById('replace1');
      if(this.replace_card_name != null && this.without_pair_array[0] != null){
        this.replace_card_by_name(this.replace_card_name,this.without_pair_array);
        //this.stage_result

        if(second_cell.hasChildNodes){
          let child_elem = second_cell.firstChild;
          while(child_elem){
            second_cell.removeChild(child_elem);
            child_elem = second_cell.firstChild;
          }
        }

        if(first_cell.hasChildNodes){
          let child_elem = first_cell.firstChild;
          while(child_elem){
            first_cell.removeChild(child_elem);
            child_elem = first_cell.firstChild;
          }
          first_cell.appendChild(this.result_card_elem_render(this.without_pair_array[0].title,this.without_pair_array[0].color));
        }else{
          first_cell.appendChild(this.result_card_elem_render(this.without_pair_array[0].title,this.without_pair_array[0].color));
        }

        for(let i of this.stage_result){
          console.log(`${i.id} - ${i.title}`);
        }
        this.ids = [];
        for(let i = 0;i<this.stage_result.length;i++){
          this.ids.push(this.stage_result[i].id)
        }
        for(let i in this.ids){
          console.log(`ids array - ${this.ids[i]}`);
        }
      }else{
        console.log('Dont have a without pair card, or not choose card for replace')
      }
    });

    finishBtn.addEventListener('click',()=>{
      finishBtn.classList.add('hide');
      $.ajax({
        url: `/api/v1/profession_cards/add_ids`,
        method: "POST",
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        data: {
          ids: ids,
        },
        success: (response)=>{
          console.log(`response: ${response}`)
        }
      });

    });
  }

//ending(container, stage_html, paginator, nextBtn, finishBtn)
  ending(container,paginator,stage_html,nextBtn){

    let first_cell = document.getElementById('replace1');
    let finishBtn = document.getElementById('finishBtn');
    let res_container = document.getElementById('profession-cards_result');
    if(this.current_stage == this.stage_limit){
      stage_html.parentNode.innerHTML = "Итог:";
      container.classList.remove('d-flex');
      container.classList.add('hide');
      paginator.classList.add('omni-hide');
      backBtn.classList.add('hide');
      nextBtn.classList.add('hide');
      //finishBtn.classList.remove('hide');

      console.log('end result');
      //this.stage_result_final = this.stage_result
      //let groups = this.cards_array_groups(first_stage_result);

        first_cell.appendChild(this.result_card_elem_render(this.without_pair_array[0].title,this.without_pair_array[0].color));
      //console.log(groups);
      this.group_render(this.stage_result_final,res_container);
    }
  }
  card_init(){
    $.ajax({
      url: '/api/v1/profession_cards/get_cards',
      method: 'GET',
      dataType: 'JSON',
      async: false,
      error: (xhr, status, error) => {
        console.error('AJAX Error: ' + status + error);
      },
      success: (response) => {
        console.log(JSON.stringify(response))
        //for (let i of response['result']){
        for (let i of response){
          let obj = new ProfessionCard(i.id,i.name,i.description,i.category,i.color,i.talent_type)
          this.cards_array.push(obj)
        }

      }
    });
    //var pairs = set_card_pairs_by_color(prof_cards_array);
  }

  result_card_elem_render(card_name, card_color){
    result_card = document.createElement('div');
    result_card.classList.add('result-card');
    result_card.style.border = `5px solid ${card_color == '#ffffff' ? '#f5f5f5' : card_color}`;
    result_card.innerHTML = card_name;
    console.log(result_card);
    return result_card;
  }

  replace_card_by_name(card_name,card_without_pair_array){
    let result_container = document.getElementById('profession-cards_result');
    console.log('card for replace name: ' + card_name)
    console.log('CWPA: ' + this.without_pair_array[0].title);
    if(this.without_pair_array[0] != undefined){
      console.log('is here' + this.stage_result_final.length)
      for(let i = 0; i < this.stage_result_final.length; i++){
        console.log('is here 2')
        if(this.stage_result_final[i].title == card_name){
          this.stage_result_final[i].is_without_pair = true;
          let tmp = this.stage_result_final[i];
          this.stage_result_final[i] = this.without_pair_array[0];
          this.without_pair_array[0] = tmp;
          this.stage_result_final[i].without_pair_array = false;
          console.log('prepare to render group')
          this.group_render(this.stage_result_final,result_container);
          //return;
        }
      }
    }else{
      console.log('something went wrong')
      //return;
    }
  }

  set_card_pairs(array){
    for(let first_card of array){
      for(let second_card of array){
        if(second_card.color != first_card.color && second_card.is_inArray != true && first_card.is_inArray != true){
          second_card.is_inArray = true;
          first_card.is_inArray = true;
          second_card.is_without_pair = false;
          first_card.is_without_pair = false;
          this.pairs.push([first_card,second_card]);
        }
      }
    }

    for(let card of array){
      if(card.is_inArray == false){
        //pair_array.push([card]);
        this.without_pair_array.push(card);
        console.log(`${card.title} is without pair: ${card.is_without_pair}`)
      }
    }
    for(let current = 0;current<this.pairs.length;current++){
      console.log(`in set card pairs 1: ${this.pairs[current][0].title} | 2: ${this.pairs[current][1] == undefined ? null : this.pairs[current][1].title}`);
    }
  }


  modal_init(card_name,card_array,without_pair_array){

    let card_modal_back = document.getElementById('prof-card_faded-back');
    let card_modal = document.getElementById('prof-card_modal');
    let card_modal_icon = document.getElementById('prof-card_modal-icon');

    let card_modal_exit = document.getElementById('prof-card_modal-exit');
    let card_modal_info = document.getElementById('prof-card_modal-info');

    let card_modal_type = document.getElementById('prof-card_type-help');

    let card_modal_title = document.getElementById('prof-card_modal-title');
    let card_modal_text = document.getElementById('prof-card_modal-text');
    let card_modal_type_title = document.getElementById('prof-card_type-help_title');
    let card_modal_type_text = document.getElementById('prof-card_type-help_text');

    for(let i = 0; i < this.cards_array.length; i++){
      if(this.cards_array[i].title == card_name){
        card_modal_title.innerHTML = this.cards_array[i].title;
        card_modal_text.innerHTML = this.cards_array[i].description;
        card_modal_type_title.innerHTML = this.cards_array[i].type.title;
        card_modal_type_text.innerHTML = this.cards_array[i].type.description;
        let color = this.cards_array[i].color == '#ffffff' ? '#d5d5d5' : this.cards_array[i].color;
        card_modal.style.borderColor = color;
        card_modal_info.style.borderColor = color;
        card_modal_info.style.color = color;
        card_modal_exit.style.borderColor = color;
        card_modal_exit.style.color = color;
        card_modal_type.style.borderColor = color;
        card_modal_icon.style.backgroundColor = color;
      }
    }
    for(let i = 0; i < without_pair_array.length; i++){
      if(without_pair_array[i].title == card_name){
        card_modal_title.innerHTML = without_pair_array[i].title;
        card_modal_text.innerHTML = without_pair_array[i].description;
        card_modal_type_title.innerHTML = without_pair_array[i].type.title;
        card_modal_type_text.innerHTML = without_pair_array[i].type.description;
        let color = this.cards_array[i].color == '#ffffff' ? '#f5f5f5' : without_pair_array[i].color;
        card_modal.style.borderColor = color;
        card_modal_info.style.borderColor = color;
        card_modal_info.style.color = color;
        card_modal_exit.style.borderColor = color;
        card_modal_exit.style.color = color;
        card_modal_type.style.borderColor = color;
        card_modal_icon.style.backgroundColor = color;
      }
    }
    card_modal_back.classList.remove('hide');
  };

  group_render(card_array, res_container){
    let group_array = this.card_array_groups(this.cards_array);
    console.log('rendering...');
    //let res_container = document.getElementById('profession-cards_result');
    res_container.classList.remove('hide');

    let elements = res_container.getElementsByClassName('group-row'); // Обновление результата
    while(elements[0]){
      elements[0].parentNode.removeChild(elements[0]);
    }

    //let result_card_row
    //let result_card_container;
    //let result_card_radio;
    //let result_card;

    for(let group = 0; group < group_array.length; group++){

      let result_card_row = document.createElement('div');
      result_card_row.classList.add('group-row');
      result_card_row.setAttribute('id',`group-${group}`);

      console.log(result_card_row);
      res_container.append(result_card_row);
      for(let card = 0; card < group_array[group].length; card++){

        console.log(`card group is ${group_array[group][card].title}`)
        let result_card_container = document.createElement('div');
        result_card_container.classList.add('result-card_container');
        let result_card_radio = document.createElement('input');
        result_card_radio.classList.add('result-card_radio');
        result_card_radio.setAttribute('type','radio');
        result_card_radio.setAttribute('name','result-card');

        result_card_row.append(result_card_container);
        result_card_container.append(this.result_card_elem_render(group_array[group][card].title, group_array[group][card].color));
        result_card_container.append(result_card_radio);

      }
    }
  }

  card_array_groups(){
    let card_group_array = [];
    let types_array = [];
    let tmp;
    for(let i = 0; i < this.stage_result_final.length; i++){
      if(!types_array.includes(this.stage_result_final[i].color)){
        types_array.push(this.stage_result_final[i].color);
        card_group_array.push([this.stage_result_final[i]]);
      }
    }
    for(let i = 0; i < this.stage_result_final.length; i++){
      for(let j = 0; j < card_group_array.length; j++){
        if(this.stage_result_final[i].color == card_group_array[j][0].color
          && this.stage_result_final[i].title != card_group_array[j][0].title){
          card_group_array[j].push(this.stage_result_final[i]);
        }
      }
    }
    for(let i = card_group_array.length - 1; i > 0 ; i--){
      for(let j = 0; j < i; j++){
        if(card_group_array[j].length > card_group_array[j + 1].length){
          let tmp = card_group_array[j];
          card_group_array[j] = card_group_array[j+1];
          card_group_array[j+1] = tmp;
        }
      }
    }
    return card_group_array.reverse();
  }

}

class IntrapersonalCardTable extends CardTable{
  stage_limit = 4
  results = []
  without_pair_array = []
  constructor(){
    super()

    //this.card_init();
    //this.set_card_pairs(this.cards_array);
    let res_container = document.getElementById('profession-cards_result');
    let intra_card_help = document.getElementById('intra-card-help');
    let intra_card_help_title = document.getElementById('intra-card-help_title');
    let intra_card_help_text = document.getElementById('intra-card-help_text');
    res_container.addEventListener('mouseover',(e)=>{
      if(e.target.className == "result_item"){
        console.log(e.target.innerText);

        intra_card_help.classList.remove('hide');
        intra_card_help.style.top = e.clientY + 'px';
        intra_card_help.style.left = e.clientX - 100 + 'px';
        for(let i = 0; i<this.cards_array.length;i++){
          if(this.cards_array[i].title == e.target.innerText){
            intra_card_help_title.innerHTML = this.cards_array[i].title;
            intra_card_help_text.innerHTML = this.cards_array[i].description
          }
        }
        console.log('find a color: ' + e.target.parentNode.getAttribute('color'));
        intra_card_help.style.borderColor = e.target.parentNode.getAttribute('color');
        intra_card_help_title.style.color = e.target.parentNode.getAttribute('color');

      }
    });
    res_container.addEventListener('mouseout',(e)=>{
      if(e.target.className == "result_item"){
        console.log(e.target.innerText);
        intra_card_help.classList.add('hide');

      }
    });

  }

  ending(container,paginator,stage_html,nextBtn){
    this.results.push(this.stage_result_final);
    if(this.current_stage == this.stage_limit){
      stage_html.parentNode.remove();
      container.classList.remove('d-flex');
      container.classList.add('hide');
      paginator.classList.add('omni-hide');
      backBtn.classList.add('hide');
      nextBtn.classList.add('hide')
      //finishBtn.classList.remove('hide');
      //console.log('results array 1:'+ this.results[0] + ' ' + this.results[0].length)
      //console.log('results array 2:'+ this.results[1] + ' ' + this.results[1].length)
      //console.log('results array 3:'+ this.results[2] + ' ' + this.results[2].length)

      this.group_render()

    }

  }

  group_render(){
    let fs_result = document.getElementById('first_stage_result');
    let ss_result = document.getElementById('second_stage_result');
    let th_result = document.getElementById('third_stage_result');
    let res_container = document.getElementById('profession-cards_result');
    res_container.classList.remove('omni-hide');

    for(let i = 0; i<this.results.length;i++){
      if(i==0){
        for(let first_i = 0; first_i < this.results[i].length; first_i++){
          let result_item = document.createElement('p');
          result_item.classList.add('result_item');
          result_item.innerHTML = this.results[i][first_i].title;
          fs_result.appendChild(result_item);
        }
        if(this.results[i].length > 10){
          fs_result.style.width = 400 + "px";
        }
      }
      if(i==1){
        for(let second_i = 0; second_i < this.results[i].length; second_i++){
          let result_item = document.createElement('p');
          result_item.classList.add('result_item');
          result_item.innerHTML = this.results[i][second_i].title;
          ss_result.appendChild(result_item);
        }
        if(this.results[i].length > 10){
          ss_result.style.width = 400 + "px";
        }
      }
      if(i==2){
        for(let third_i = 0; third_i < this.results[i].length; third_i++){
          let result_item = document.createElement('p');
          result_item.classList.add('result_item');
          result_item.innerHTML = this.results[i][third_i].title;
          th_result.appendChild(result_item);
        }
        if(this.results[i].length > 10){
          th_result.style.width = 400 + "px";
        }
      }
    }



  }

  card_init(){
    $.ajax({
      url: '/api/v1/intrapersonal_cards/get_cards',
      method: 'GET',
      dataType: 'JSON',
      async: false,
      error: (xhr, status, error) => {
        console.error('AJAX Error: ' + status + error);
      },
      success: (response) => {
        console.log(JSON.stringify(response))

          for (let i of response['result']){
          let obj = new IntrapersonalCard(i.id,i.title,i.description)
          this.cards_array.push(obj)
        }

      }
    });

  }


}

function hideAdminOptions(arg){
  if(arg){
    let sidebar = document.getElementById('sidebar-content');
    let header = document.getElementById('header-content');
    let page = document.getElementById('body-content');
    sidebar.classList.add('omni-hide');
    header.classList.add('omni-hide');
    page.style.padding = 0 + 'px';
  }else{
    return false;
  }
}

$(document).on('turbolinks:load',()=>{
  /*let prof_cards_array = [];
  let without_pair_array = [];*/
  if(window.location.href.includes('admin/profession_cards')){
    let prof_card_table = new ProfessionCardTable();
    hideAdminOptions(true);
  }
  else if(window.location.href.includes('admin/intrapersonal_cards')){
    let intra_card_table = new IntrapersonalCardTable();
    hideAdminOptions(true);

  }else{
    hideAdminOptions(false);
    //prof_cards_array = [];
    //console.log('no prof cards')

  }
});
