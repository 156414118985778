// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require.context('../images', true)
const imagePath = (name) => images(name, true)


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "@fortawesome/fontawesome-free/js/all";
// import "@fortawesome/fontawesome-free/css/all"

import tinymce from 'tinymce/tinymce';
import { tinyMce } from "../vendor/tinyMce";

require('select2')
import 'select2'
import 'select2/dist/css/select2.css'
// import 'select2-bootstrap-theme/dist/select2-bootstrap.css'

import 'cocoon-js'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// import the bootstrap javascript module
import "bootstrap"

// import the application.scss we created for the bootstrap CSS (if you are not using assets stylesheet)
import "../stylesheets/application"

var jQuery = require('jquery')

import './sidebar'
import './multistep-form'
import './draft'
import './new-profession-cards'
//import './intrapersonal-cards'
require("./jquery.inputmask.js")

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

document.addEventListener("turbolinks:load", () => {
  tinyMce();
});

$(document).on('turbolinks:load', function () {
   tinymce.remove();
   tinyMce();
});

document.addEventListener("turbolinks:load", () => {
  $('.tag-select-multiple').select2({
    // theme: "bootstrap",
    multiple: true,
    tags: true,
    width: '100%',
    language: "ru",
    placeholder: "Выберите значение из списка"
    // tokenSeparators: [',', ' ']
  });
});

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  $('.dropdown.notifications ul a.nav-link').click(function (e) {
    e.stopPropagation();
    $(this).tab('show');
  });
})


//Для пользовательского сайдбара - начало

//Для пользовательского сайдбара - конец




$(document).on('turbolinks:load', function() {
// $(document).ready(function() {
// $(function() {
  if( typeof(Storage)!== "undefined" ) {
    var collapsed = (window.localStorage.getItem('collapsed') == 'true') ? true : false;

    if( collapsed ) {
      SidebarCollapsed();
      //console.log('Панель была свёрнута, collapsed: ' + collapsed);
    } else {
      SidebarExpanded();
      SidebarExpandedLinkShow();

      //console.log('Панель развёрнута, collapsed: ' + collapsed);
    }
  } else {
    // Sorry! No Storage support..
    console.log('Sorry! No Storage support..');
  }

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  function SidebarExpanded () {
    $('#collapse-icon').removeClass('fa-angle-double-right');
    $('#collapse-icon').addClass('fa-angle-double-left');

    $('.menu-collapsed').removeClass('d-none');
    $('.sidebar-submenu').removeClass('d-none');
    $('.submenu-icon').removeClass('d-none');

    $('#sidebar-container').addClass('sidebar-expanded');
    $('#body-content').addClass('body-content');
    $('#sidebar-content').addClass('sidebar-content');

    $('.sidebar-separator-title').removeClass('d-none');
    $('.sidebar-header').removeClass('d-none');
  }

  function SidebarCollapsed () {
    $('#collapse-icon').removeClass('fa-angle-double-left');
    $('#collapse-icon').addClass('fa-angle-double-right');

    $('.menu-collapsed').removeClass('d-flex');
    $('.sidebar-submenu').removeClass('d-flex');
    $('.submenu-icon').removeClass('d-flex');

    $('.menu-collapsed').addClass('d-none');
    $('.sidebar-submenu').addClass('d-none');
    $('.submenu-icon').addClass('d-none');

    $('#sidebar-container').addClass('sidebar-collapsed');
    $('#body-content').addClass('body-collapsed-content');
    $('#sidebar-content').addClass('sidebar-collapsed-content');

    $('.sidebar-separator-title').addClass('d-none');
    $('.sidebar-header').addClass('d-none');
  }

  function SidebarExpandedLinkShow() { //Показывает на сайдбаре где мы сейчас находимся (сайдбар развернут)
    let link = window.location.href;
    MenuLinkShow($('#sidebar-container').children('.list-group').children('a'),link);
    SubmenuLinkShow("#submenu-users",link);
    SubmenuLinkShow("#submenu-products",link);
    SubmenuLinkShow("#submenu-catalogs",link);
    // console.log($('#sidebar-container').children('.list-group').children('a'))
  }

  function MenuLinkShow(query,link){ //Функция поиска ссылки в обычном меню
    query.each((i,val)=>{
      if(link.includes(val)){
        $(val).addClass('sidebar-item-active');
        $(val).removeClass('sidebar-item');
        return true;
      }
    });
  }

  function SubmenuLinkShow(selector,link){ //Функция поиска ссылки в подменю
    $(selector).children().each((i,val)=>{
      if(link.includes(val)){
        $(selector).addClass('show');
        $(val).addClass('sidebar-item-active');
        $(val).removeClass('sidebar-item');
        return true;
      }
    });
  }
})

//Multistep test
$(document).on('turbolinks:load', function() {
  function MultiStepFormLoad(){
    let link = window.location.href;

  }

})
